// src/App.js
import React from 'react';
import styled from 'styled-components';
import Header from './components/Header';
import Footer from './components/Footer';
import ComingSoon from './components/ComingSoon';
import GlobalStyle from './GlobalStyle';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  @media (max-width: 480px) {
    overflow-y: auto; /* Enable vertical scrolling */
  }
`;

const HeaderContainer = styled.header`
  height: 20vh;
`;

const MainContent = styled.main`
  height: 70vh;
`;

const FooterContainer = styled.footer`
  height: 10vh;
`;

const App = () => {
  return (
    <AppContainer>
      <GlobalStyle />
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <MainContent>
        <ComingSoon />
      </MainContent>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </AppContainer>
  );
};

export default App;
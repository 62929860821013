// src/components/ComingSoon.js
import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import centralSvg from '../assets/bike.svg'; // Adjust the path as needed
import svg1 from '../assets/green-beans.svg';
import svg2 from '../assets/chilli.svg';
import svg3 from '../assets/cilantro.svg';
import svg4 from '../assets/curry-leavs.svg';
import svg5 from '../assets/rice-bag.svg';
import svg6 from '../assets/tomato.svg';

const slideUp = keyframes`
  0% { transform: translateY(20px); }
  100% { transform: translateY(-20px); }
`;

const slideDown = keyframes`
  0% { transform: translateY(-20px); }
  100% { transform: translateY(20px); }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  height: 100%;
  background-color: #FBBE28;
  color: #000000;
  text-align: center;
  overflow: hidden; /* Hide any overflow */
  position: relative;

  @media (max-width: 768px) {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh;
  }
`;

const CentralSvgContainer = styled.div`
  grid-column: 2 / 6;
  grid-row: 1 / 5;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    grid-column: 1 / 7;
  }

  @media (max-width: 480px) {
    grid-column: 1 / 5;
  }
`;

const CentralSvg = styled.img`
  width: 100%;
  height: 100%;
`;

const BottomRowContainer = styled.div`
  grid-column: 1 / 7;
  grid-row: 5 / 7;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: 1fr;
  gap: 10px;
  padding: 10px;

  @media (max-width: 768px) {
    grid-column: 1 / 7;
    grid-template-columns: repeat(8, 1fr);
  }

  @media (max-width: 480px) {
    display: none;
  }
`;

const MobileBottomRowContainer = styled.div`
  display: none;

  @media (max-width: 480px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto auto;
    gap: 10px;
    padding: 10px;
    width: 100%;
  }
`;

const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const BottomSvgUp = styled.img`
  width: 100%;
  height: 100%;
  animation: ${slideUp} 2s infinite alternate;
`;

const BottomSvgDown = styled.img`
  width: 100%;
  height: 100%;
  animation: ${slideDown} 2s infinite alternate;
`;

const ContentContainer = styled.div`
  grid-column: 4 / 6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @media (max-width: 480px) {
    grid-column: 1 / 4;
    grid-row: 2 / 3;
  }
`;



const IframeContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const Iframe = styled.iframe`
  width: 80%;
  height: 80%;
  border: none;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 25px;
  font-weight: bold;
  background: #FBBE28;
  color: #000000;

`;
 
const StyledButton = styled.button`
  background: #fffcd5;
  box-shadow: 4px 4px #ffe00b, 9px 9px #151515;
  color: #151515;
  text-transform: lowercase;
  border: solid 2px #151515;
  text-decoration: none;
  padding: 18px 32px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  z-index: 1;
  transition: 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  cursor: pointer;
  overflow: hidden;
  transition-delay: 0s !important;
  text-transform: uppercase !important;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 0%;
    height: 100%;
    background: #151515;
    z-index: -1;
    transition: 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  &:hover::before {
    width: 100%;
    left: 0;
    right: unset;
  }

  &:hover {
    box-shadow: 0 0 #ffe00b, 0 0 #151515;
    color: white;
  }
`;


const ComingSoon = () => {
  const [showIframe, setShowIframe] = useState(false);

  const handleButtonClick = () => {
    setShowIframe(true);
  };

  const handleCloseClick = () => {
    setShowIframe(false);
  };

  return (
    <Container>
      <CentralSvgContainer>
        <CentralSvg src={centralSvg} alt="Central SVG" />
      </CentralSvgContainer>
      <BottomRowContainer>
        <SvgContainer>
          <BottomSvgUp src={svg1} alt="SVG 1" />
        </SvgContainer>
        <SvgContainer>
          <BottomSvgDown src={svg2} alt="SVG 2" />
        </SvgContainer>
        <SvgContainer>
          <BottomSvgUp src={svg3} alt="SVG 3" />
        </SvgContainer>
        <ContentContainer>
          <h2>We are getting ready to deliver</h2>
          <StyledButton onClick={handleButtonClick}>Get Updates</StyledButton>
          {showIframe && (
            <IframeContainer>
              <Iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSdjRUyPUNijCO3asQSD6dZxjOKp-0sQ4wwrofNNlMdz05VFfA/viewform?embedded=true"
                title="Google Form"
              ></Iframe>
              <CloseButton onClick={handleCloseClick}>Close</CloseButton>
            </IframeContainer>
          )}
        </ContentContainer>
        <SvgContainer>
          <BottomSvgDown src={svg4} alt="SVG 4" />
        </SvgContainer>
        <SvgContainer>
          <BottomSvgUp src={svg5} alt="SVG 5" />
        </SvgContainer>
        <SvgContainer>
          <BottomSvgDown src={svg6} alt="SVG 6" />
        </SvgContainer>
      </BottomRowContainer>
      <MobileBottomRowContainer>
        <SvgContainer>
          <BottomSvgUp src={svg1} alt="SVG 1" />
        </SvgContainer>
        <SvgContainer>
          <BottomSvgDown src={svg2} alt="SVG 2" />
        </SvgContainer>
        <SvgContainer>
          <BottomSvgUp src={svg3} alt="SVG 3" />
        </SvgContainer>
        <ContentContainer>
          <h2>We are getting ready to deliver</h2>
          <StyledButton onClick={handleButtonClick}>Get Updates</StyledButton>
          {showIframe && (
            <IframeContainer>
              <Iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSdjRUyPUNijCO3asQSD6dZxjOKp-0sQ4wwrofNNlMdz05VFfA/viewform?embedded=true"
                title="Google Form"
              ></Iframe>
              <CloseButton onClick={handleCloseClick}>Close</CloseButton>
            </IframeContainer>
          )}
        </ContentContainer>
        <SvgContainer>
          <BottomSvgDown src={svg4} alt="SVG 4" />
        </SvgContainer>
        <SvgContainer>
          <BottomSvgUp src={svg5} alt="SVG 5" />
        </SvgContainer>
        <SvgContainer>
          <BottomSvgDown src={svg6} alt="SVG 6" />
        </SvgContainer>
      </MobileBottomRowContainer>
    </Container>
  );
};

export default ComingSoon;

// src/GlobalStyle.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; /* Ensure no scrollbars appear */
    background: #FBBE28;
    color: #000000;
    letter-spacing: 1.5px;
    font-family: sans-serif;
  }
`;

export default GlobalStyle;
